/* pt-serif-regular - latin */
@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 400;
  src: url("pt-serif-v11-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("PT Serif"), local("PTSerif-Regular"),
    url("pt-serif-v11-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("pt-serif-v11-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("pt-serif-v11-latin-regular.woff")
      format("woff"); /* Modern Browsers */
}
/* pt-serif-700 - latin */
@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 700;
  src: url("pt-serif-v11-latin-700.eot"); /* IE9 Compat Modes */
  src: local("PT Serif Bold"), local("PTSerif-Bold"),
    url("pt-serif-v11-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("pt-serif-v11-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("pt-serif-v11-latin-700.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url("roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-regular.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-500.woff") format("woff"); /* Modern Browsers */
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("roboto-v20-latin-700.eot"); /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("roboto-v20-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("roboto-v20-latin-700.woff") format("woff"); /* Modern Browsers */
}

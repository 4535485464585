
@font-face {
    font-family: 'Druk Text Web';
    src: url('DrukText-Medium-Web.eot');
    src: url('DrukText-Medium-Web.eot?#iefix') format('embedded-opentype'),
         url('DrukText-Medium-Web.woff2') format('woff2'),
         url('DrukText-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  }
  
  .DrukText-Medium-Web {
    font-family: 'Druk Text Web';
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
  }